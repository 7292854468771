import React from 'react';
import styled from 'styled-components';
import { Grid, GridCol } from '@naf/grid';
import { nafColor } from '@nafcore/theme';
import { spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { useHistory } from 'react-router-dom';
import { formattedPrice } from '../../lib/formattedPrice';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { DEFAULT_IMG_URL, MEMBERPAGES_URL } from '../../lib/constants';
import Loader from '../../components/loader';
import { InvoiceStatus, useInvoice } from '../../hooks/useInvoice';
import useSelector from '../../redux/typedHooks';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

interface Props {
  match: { params: { paymentId: string } };
}

export const InvoicePaymentReceipt = ({
  match: {
    params: { paymentId },
  },
}: Props) => {
  const invoicePayment = useInvoice(paymentId);
  const baseUrl = useSelector((state) => new URL(state.application.baseUrl));
  const history = useHistory();
  const windowPath = useWindowLocation();

  return (
    <LayoutWithMainContent
      title="Betalingskvittering"
      description="Henter informasjon om kjøp"
      url={windowPath}
      imgUrl={DEFAULT_IMG_URL}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <Grid>
        {!invoicePayment && (
          <>
            <GridCol s="12" m="12" l="12" xl="12">
              <Header variant={TextVariant.Header1}>Kjøpskvittering</Header>
            </GridCol>
            <GridCol s="12" m="12" l="12" xl="12">
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
              <AnimatedText variant={TextVariant.Header3}>
                Takk for din betaling. Vi er glad for at du fortsatt vil være NAF-medlem.
              </AnimatedText>
            </GridCol>
          </>
        )}
        {invoicePayment && invoicePayment.paymentOrderStatus === InvoiceStatus.PAID && (
          <>
            <GridCol s="12" m="12" l="12" xl="12">
              <Header variant={TextVariant.Header1}>Ditt kjøp er gjennomført</Header>
            </GridCol>
            <GridCol s="12" m="12" l="8" xl="8">
              <Text variant={TextVariant.ArticleText}>Takk for din betaling. </Text>
              <StyledButton href={`${baseUrl}${MEMBERPAGES_URL}/mitt-medlemskap`}>Gå tilbake til Mitt NAF</StyledButton>
            </GridCol>
            <GridCol s="12" m="12" l="4" xl="4">
              <Summary>
                <SummaryHeader variant={TextVariant.Header3}>Ditt kjøp</SummaryHeader>
                {invoicePayment.summary &&
                  invoicePayment.summary.invoiceLine.map((invoiceLine) => (
                    <div key={invoiceLine.productNumber}>
                      <LineWrapper>
                        <Text variant={TextVariant.ArticleTextHeader}>{invoiceLine.productName}</Text>
                        <Price variant={TextVariant.ArticleTextHeader}>
                          {formattedPrice(invoiceLine.listPrice)} kr
                        </Price>
                      </LineWrapper>
                    </div>
                  ))}
                <LineWrapper $withTopBorder>
                  <LineText variant={TextVariant.ArticleTextHeader}>Totalt:</LineText>
                  <TotalPrice variant={TextVariant.ArticleTextHeader}>
                    {invoicePayment.summary.amount && formattedPrice(invoicePayment.summary.amount)} kr
                  </TotalPrice>
                </LineWrapper>
              </Summary>
            </GridCol>
          </>
        )}
        {invoicePayment &&
          (invoicePayment.paymentOrderStatus === InvoiceStatus.NONE ||
            invoicePayment.paymentOrderStatus === InvoiceStatus.CANCELED) && (
            <>
              <GridCol s="12" m="12" l="12" xl="12">
                <Header variant={TextVariant.Header1}>Ditt kjøp ble ikke gjennomført</Header>
              </GridCol>
              <GridCol s="12" m="12" l="8" xl="8">
                <Text variant={TextVariant.ArticleText}>
                  Din betaling ble dessverre kansellert eller avvist, vennligst forsøk på nytt eller kontakt
                  kundeservice dersom problemet vedvarer.
                </Text>
                <StyledButton
                  onClick={() => {
                    history.push(`/${MEMBERPAGES_URL}/mitt-medlemskap`);
                  }}
                >
                  Gå tilbake til Mitt NAF
                </StyledButton>
              </GridCol>
            </>
          )}
      </Grid>
    </LayoutWithMainContent>
  );
};

const LineWrapper = styled.div<{ $withTopBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  border-top: ${({ $withTopBorder }) => ($withTopBorder ? '1px solid rgba(10, 10, 10, 0.12)' : undefined)};
  border-top: ${({ $withTopBorder }) => ($withTopBorder ? '1px solid rgba(10, 10, 10, 0.12)' : undefined)};
  padding-top: ${({ $withTopBorder }) => ($withTopBorder ? '32px' : undefined)};

  h5 {
    margin-top: 0;
  }
`;

const TotalPrice = styled(Text)`
  margin: 0;
  font-size: 1.375rem;
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing.space32};
`;

const LineText = styled(Text)`
  margin: 0;
  margin-bottom: 3px;
`;

const Price = styled(Text)`
  margin: 0;
  margin-bottom: 3px;
  min-width: 70px;
  text-align: right;
`;

const Summary = styled.div`
  background: ${nafColor.primary.dew};
  background-color: ${nafColor && nafColor.signature.yellow10 ? nafColor.signature.yellow10 : `#FFFAEC`};
  padding: ${spacing.space32};
`;

const SummaryHeader = styled(Text)`
  margin-top: 0;
`;

const Header = styled(Text)`
  margin-top: 58px;
  max-width: 545px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0;
`;

const AnimatedText = styled(Text)`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 25px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 25px;
    }
  }
`;
